.page-footer {
    /*background-color: #415073;*/
    background-color: #1a3c54;
    /* box-shadow: 0 -5px 35px rgba(82, 82, 82, 0.15); */
    position: relative;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(var(--footer-height, 700) + 2px);
    z-index: 0;
}

.logo-page-footer-parent {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    padding-bottom: 0.7em;
}

.logo-page-footer-parent .logo {
    height: 80pt;
    margin-bottom: 10;
    margin-top: 10;
    aspect-ratio: 1.1023809524;
    display: flex;
    justify-content: center;
}

.logo-page-footer-parent :hover {
    transition: 0.25s;
    filter: brightness(80%);
}

.list-page-footer-parent {
    display: block;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    padding-bottom: 0.5em;
}

.list-unstyled > li {
    height: 22pt;
}

.settings-page-footer-parent {
    margin-top: 1em;
}

.footer-copyright {
    margin-top: 1em;
    border-top: 0.5px solid white;
    color: white;
    position: absolute;
    bottom: 0;
    width: 100%;
}

li {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
}

a {
    /* text-decoration: none !important; */
    position: relative;
    text-decoration: none;
}

a span {
    text-decoration: none !important;
}

.listGroup {
    margin-top: 1em;
    --bs-list-group-border-width: none !important;
}

.footer-sm-link {
    text-decoration: none;

    letter-spacing: 0.02em;
    color: rgba(255, 255, 255, 0.6);
    padding: 10px;
}

.footer-sm-link.fontM {
    font-size: 12pt;
    padding: 10px;
}

.footer-sm-link.fontS {
    font-size: 10pt;
    padding: 0px;
}

@media screen and (max-width: 667px) {
    .footer-sm-link.fontS {
        font-size: 9pt;
    }
}

list-group-item > .footer-sm-link.fontS {
    padding: 0;
}

.footer-sm-link:hover {
    color: white;
}

.footer-sm-link:active {
    color: rgba(255, 255, 255, 0.6);
}

.cookie {
    text-decoration: underline;
    text-decoration-thickness: 1.5px;
    line-height: 150%;
    margin-bottom: 5px;
    text-underline-position: under;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: rgba(255, 255, 255, 0.6);
    background-color: transparent !important;
    border: none !important;
}

.cookie:hover {
    color: white;
}

.mg-mailto {
    line-height: 1.5;
    letter-spacing: 0.02em;
    display: inline-block;
    font-size: larger;
    font-weight: 600;
    text-decoration: none !important;
}

.mg-mailto:active {
    color: black;
    background-color: white;
    border-radius: 7px;
    text-decoration: none !important;
}

.mg-mailto:link {
    color: white;
    background-color: none;
    border-radius: 7px;
    text-decoration: none !important ;
}

.mg-telephone {
    font-size: larger;
    word-spacing: 5pt;
    font-weight: 600;
    text-decoration: none !important;
}

.mg-telephone:active {
    color: black;
    background-color: white;
    border-radius: 7px;
    text-decoration: none !important;
}

.mg-telephone:link {
    color: white;
    background-color: none;
    border-radius: 7px;
    text-decoration: underline;
}

.transition {
    transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
}

.transitionMg {
    transition: all 0.6s;
    -moz-transition: all 0.6s;
    -webkit-transition: all 0.6s;
    -o-transition: all 0.6s;
}

.transitionMg:hover {
    color: rgba(255, 255, 255, 0.6);
}

/**
* Animations
*/

@keyframes scale_transition {
    0% {
        transform: scaleX(1);
    }
    50% {
        transform: scaleX(0);
    }
    100% {
        transform: scaleX(1);
    }
}

#footer {
    opacity: 1;
    transition: opacity 0.25s;
}

#footer.fade {
    opacity: 0;
    transition: opacity 0.25s;
}

.list-group-flush {
    align-items: start;
}

.list-group-flush > .list-group-item .footer-sm-link .fontM {
    padding-top: 5px;
    padding-bottom: 5px;
}

.list-group-flush > .list-group-item {
    padding-top: 2px;
    padding-bottom: 2px;
}

.py-3 {
    padding-top: 0.5rem !important;
}
